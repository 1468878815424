<template>
  <u-btn class="pos-rlt" size="sm" no-caps>
    <span>Mais opções <i class="fa fa-chevron-down" /></span>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom middle" self="top middle">
      <div class="menu">
        <ul>
          <menu-options-item @click="$refs.lanceManual.digitarLance()" close>Efetuar Lance Manual</menu-options-item>
          <menu-options-item @click="$emit('statusAberto')" close>Voltar status para Aberto</menu-options-item>
          <menu-options-item  @click="leilaoWindow(lote.leilao.id)" close>Editar leilão</menu-options-item>
        </ul>
        <slot />
      </div>
    </u-popover>
    <lance-manual ref="lanceManual" @lance="(v, boleta) => lance(v, boleta)" />
  </u-btn>
</template>

<script>
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import {UPopover} from "uloc-vue"
import leilaoWindow from "components/leiloes/windows/leilao"
import LanceManual from "components/leiloes/components/controlador/include-v2/LanceManual.vue"

export default {
  name: 'AuLoteExtraOpts',
  props: {
    lote: {required: true}
  },
  components: {
    MenuOptionsItem,
    UPopover,
    LanceManual
  },
  methods: {
    leilaoWindow: leilaoWindow,
    lance(v, boleta) {
      this.$emit('lanceManual', v, boleta)
    }
  }
}
</script>
